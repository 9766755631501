import React from "react"

import { useBadge } from "../../../hooks/useBadge"

export const withBadge =
  Component =>
  ({
    name = "Badge",
    children,
    tags,
    layout,
    isOnSale = false,
    isSizeSoldOut = false,
    soldOutOnline,
    product
  }) => {
    const { message, colour } = useBadge(tags)
    
    if( product ) {
      try{
        let stockLevel = 0;
        const variants = product.variants;

        variants.map((variant) => {
          stockLevel += variant.quantityAvailable
        })

        isSizeSoldOut = stockLevel == 0 ? true : false;
      }
      catch{
        console.log('error')
      }
    }

    Component.displayName = name
    return (
      <Component
        message={message}
        layout={layout}
        isSizeSoldOut={isSizeSoldOut}
        isOnSale={isOnSale}
        colour={colour}
        soldOutOnline={soldOutOnline}
      >
        {children}
      </Component>
    )
  }
