import React from "react"

import { AdditionalPayments } from "../../AdditionalPayments/AdditionalPayments"
import { AddToCart } from "../../AddToCart/AddToCart"
import { Badge } from "../../Badge/Badge"
import { Message } from "../../Message/Message"
import { Price } from "../../Price/Price"
import { FindInStore } from "../../FindInStore/FindInStore"
import { SizeGuide } from "../../SizeGuide/SizeGuide"
import { DetailSwatches as Swatches } from "../../Swatches/Detail/DetailSwatches"
import { Variants } from "../../Variants/Variants"
import { Wishlist } from "../../Wishlist/Wishlist"
import { ManualOrder } from "../ManualOrder/ManualOrder"
import {
  BadgeWrapper,
  Divider,
  PriceWrapper,
  Title,
  TitleWrapper,
  VariantsWrapper,
  WishlistWrapper,
} from "../FormStyles"
import { withDetailForm } from "./withDetailForm"
import { BundleForm } from "../Bundle/BundleForm"
import { StarRating } from "../../../Okendo/StarRating"

export const DetailForm = withDetailForm(
  ({
    tags,
    title,
    product,
    selectedVariant,
    defaultVariant,
    siblings,
    selectedOptions,
    handle,
    multipleVariants,
    handleVariant,
    isOnSale,
    manualOrder,
    showAddToCart,
    price,
    descriptionHtml,
    bundle,
    bundleSubtitle,
    bundleProductsTitle,
    showFindInStore,
  }) => (
    <div>
      <Badge tags={tags} layout={"product"} isOnSale={isOnSale} product={product} />
      <TitleWrapper>
        <Title>{title}</Title>
        <WishlistWrapper>
          <Wishlist product={product} selectedVariant={selectedVariant} />
        </WishlistWrapper>
      </TitleWrapper>
      {bundle ? (
        <BundleForm
          bundle={bundle}
          bundleSubtitle={bundleSubtitle}
          bundleProductsTitle={bundleProductsTitle}
          descriptionHtml={descriptionHtml}
        />
      ) : (
        <>
          <PriceWrapper>
            <Price
              variant={selectedVariant || defaultVariant}
              layout={"product"}
            />
          </PriceWrapper>
          <BadgeWrapper>
            <Message tags={tags} layout={"product"} />
          </BadgeWrapper>
          <StarRating productId={product.id} />
          {price > 5 && (
            <AdditionalPayments variant={selectedVariant || defaultVariant} />
          )}
          <Divider />
          <Swatches
            siblings={siblings}
            selectOptions={selectedOptions}
            defaultSelectedOption={defaultVariant?.selectedOptions}
            handle={handle}
          />
          {manualOrder?.enabled && (
            <ManualOrder manualOrder={manualOrder}></ManualOrder>
          )}

          {showAddToCart && (
            <>
              {multipleVariants && (
                <VariantsWrapper>
                  <Variants
                    handleVariant={handleVariant}
                    product={product}
                    selectedOptions={selectedOptions}
                  />
                  <SizeGuide tags={tags} />
                </VariantsWrapper>
              )}
              <AddToCart
                product={product}
                selectedVariant={selectedVariant}
                layout={"product"}
                showQuantitySelector
              />
              {showFindInStore && <FindInStore />}
            </>
          )}
        </>
      )}
    </div>
  ),
)
