import React from "react"

import { withBadge } from "./withBadge"
import { Wrapper } from "./BadgeStyles"

export const Badge = withBadge(
  ({ message, children, layout, isOnSale, isSizeSoldOut, soldOutOnline, colour, product }) => (
    <Wrapper
      layout={layout}
      isOnSale={isOnSale}
      colour={isSizeSoldOut || soldOutOnline ? "#767676" : colour}
      product={product}
    >
      {children
        ? children
        : isSizeSoldOut
        ? "Sold Out Online"
        : isOnSale
        ? message || "Sale"
        : message}
    </Wrapper>
  ),
)
